/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

body {
    margin: 0;
    // font-family: Roboto, "Helvetica Neue", sans-serif;
}

* {
    font-family: "Open Sans", sans-serif;
}


@import 'ag-grid-community/styles/ag-grid.css';
@import "~ag-grid-community/styles/ag-theme-alpine.css";

button {
    border-radius: 0 !important;
}

.mat-step-label,
.mat-step-label-selected {
    font-size: 1rem !important;
}

.mat-progress-spinner.mat-progress-spinner-indeterminate-animation[mode=indeterminate] circle {
    animation-duration: 5000ms !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: #dcdddd !important;
}

// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// // Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) { ... }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

    * {
        font-size: 0.9rem;
    }

    h3 {
        font-size: 15px;
    }

    h2 {
        font-size: 0.1rem;
    }

    .mat-container {
        width: 95%;
        margin: auto;
    }


    .mat-step-icon {
        height: 25px !important;
        width: 25px !important;
    }

    .mat-step-text-label {
        height: 20px !important;
        font-size: 1rem !important;
        line-height: 20px !important;
    }

    .mat-error,
    .mat-hint {
        font-size: x-small;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

    * {
        font-size: 0.9rem;
    }

    h3 {
        font-size: 1rem;
    }

    h2 {
        font-size: 2px;
    }

    .mat-container {
        width: 95%;
        margin: auto;
    }

    .mat-step-icon {
        height: 25px !important;
        width: 25px !important;
    }

    .mat-step-text-label {
        margin: auto;
        height: 20px !important;
        font-size: 1rem !important;
        line-height: 20px !important;
    }

    .mat-error,
    .mat-hint {
        font-size: x-small;
    }

    .ag-header-cell-text {
        color: black !important;
        font-weight: bold !important;
        font-size: 14px !important;
    }

    .ag-cell-value {
        font-size: 16px !important;
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    * {
        font-size: 1rem;
    }

    h3 {
        font-size: 2rem;
    }

    h2 {
        font-size: 3rem;
    }

    .mat-step-icon {
        height: 30px !important;
        width: 30px !important;
    }

    .mat-step-text-label {
        margin: auto;
        height: 25px !important;
        font-size: 1.3rem !important;
        line-height: 25px !important;
    }

    .mat-error,
    .mat-hint {
        font-size: smaller;
    }

    .ag-header-cell-text {
        color: black !important;
        font-weight: bold !important;
        font-size: 14px !important;
    }

    .ag-cell-value {
        font-size: 16px !important;
    }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
    * {
        font-size: 1.02rem;
    }

    h3 {
        font-size: 3rem;
    }

    h2 {
        font-size: 3.75rem;
    }

    .mat-container {
        width: 80%;
    }

    .mat-step-icon {
        height: 30px !important;
        width: 30px !important;
    }

    .mat-step-text-label {
        margin: auto;
        height: 25px !important;
        font-size: 1.3rem !important;
        line-height: 25px !important;
    }

    .mat-error,
    .mat-hint {
        font-size: smaller;
    }

    .ag-header-cell-text {
        color: black !important;
        font-weight: bold !important;
        font-size: 14px !important;
    }

    .ag-cell-value {
        font-size: 16px !important;
    }

}